export const REQUEST_SCORE_URL = 'https://get.unitq.com/unitq-score-analyzer?utm_source=website';

export const Content = {
  request: {
    link: {
      href: REQUEST_SCORE_URL,
      label: 'Request Your unitQ Score',
    },
  },
  seo: {
    appendHostUrl: true,
    description: `We're a growing, talented, diverse team looking for self-motivated
    individuals who are passionate about building awesome, AI-first products`,
    src: '/images/v2/seo/home.jpg',
    title: 'Careers',
  },
  subtitle: `We're a growing, talented, diverse team looking for self-motivated
  individuals who are passionate about building awesome, AI-first products`,
  title: 'Join the **unitQ** team!',
};
