import React from 'react';

import { UqPage, UqHeroV2, UqLayoutV2, UqContainer, UqContainerV2, UqCustomerLogosV2, UqValuesBlock, UqFigure, UqTextHighlight, UqAppBarV2, UqNavbarV2, UqFooterV2 } from '@uq-components';
import { Content } from '../content/v2/careers';

import * as styles from './careers.module.scss';


export default function CareersPage() {
  return (
    <UqPage config={{ seo: Content.seo }}>
      <UqLayoutV2>
        <UqAppBarV2 className={styles.appbar}>
          <UqNavbarV2 />
        </UqAppBarV2>

        <UqHeroV2>
          <UqContainer>
            <section className={styles.headerContainer}>
              <div className={styles.title}>
                <UqTextHighlight as="h1">
                  {Content.title}
                </UqTextHighlight>
                <p className="body-1">
                  {Content.subtitle}
                </p>
              </div>
            </section>
          </UqContainer>
        </UqHeroV2>

        <section className={styles.teamPhotosContainer}>
        </section>
        <section className={styles.logos}>
          <UqContainerV2>
            <UqCustomerLogosV2 />
          </UqContainerV2>
        </section>
        <UqContainer
          fullWidth
          background="dark"
          logoProps={{
            anchor: 'topLeft',
            dark: true,
          }}
        >
          <div className={styles.valuesAndPerksBlock}>
            <UqContainer>
              <UqValuesBlock />
              <div className={styles.perksRow}>
                <UqFigure>
                  <UqFigure.Content>
                    <UqFigure.Title>{'Perks of working at **unitQ**'}</UqFigure.Title>
                    <span>
                      We&apos;re an early-stage startup with the stability and funding to offer attractive perks!
                      We have competitive compensation packages and benefits including unlimited PTO, great health insurance,
                      access to wellness programs, and last but not least - a fun and creative working environment.
                    </span>
                  </UqFigure.Content>

                  <UqFigure.Image src="/images/v2/careers/perks.png" />
                </UqFigure>
              </div>
            </UqContainer>
          </div>
        </UqContainer>
        <section className={styles.diversityContainer}>
          <UqContainer>
            <UqFigure>
              <UqFigure.Content>
                <UqFigure.Title>{'We’re committed to hiring **diverse** talent'}</UqFigure.Title>
                <span>
                  At unitQ, we believe that a diverse and inclusive team is key to building a great product.
                  We celebrate the different cultures, perspectives, and experiences reflected across our team and
                  are dedicated to continue to build a diverse team as we grow.
                </span>

                <UqFigure.Actions actions={[
                  { href: 'https://jobs.lever.co/unitq', label: 'View Jobs', type: 'button' },
                  { href: 'mailto:careers@unitq.com/', label: 'Email Us', type: 'button' },
                ]} />
              </UqFigure.Content>

              <UqFigure.Image src="/images/v2/careers/diverse-talent.png" />
            </UqFigure>
          </UqContainer>
        </section>

        <UqFooterV2 />
      </UqLayoutV2>
    </UqPage>
  );
}
